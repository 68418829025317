import React, { useState, useEffect } from 'react';
import {myConfig} from '../../config';
import { isEmpty } from 'lodash';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_CATALOG_PAGE } from '../../models/merchant/mutations';
import { Menu, Modal, Header, Checkbox, Icon, Confirm, Input, Form } from 'semantic-ui-react';
import { Button, Segment, Portal } from 'semantic-ui-react';
import DynalogProductsGrid from '../Dynalog/productsGrid'; 
import DynalogCategoriesGrid from '../Dynalog/categoriesGrid';
import ImageEdit from '../imageEdit';
import CustomizePage from '../customizePage';
import 'react-tabs/style/react-tabs.css';
import CanvasEditor from './Editor/CanvasEditor';
import ProductTemplateEditor from './Editor/productTemplateEditor';
import './pageManager.scss';
import './pageEditor.css';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';

const PageEditor = ({ page, pages, versionId, done, goTo, catalog, confirmChangesOpen, closePageEditor, resetConfirmChanges, unsavedChanges, setUnsavedChanges}) => {
	console.log(page?.position);
	const [ canvasMode, setCanvasMode ] = useState(page.pageType !== 'products');
	const [ savePage ] = useMutation(UPDATE_CATALOG_PAGE);
	const [ background, setBackground ] = useState(page.imageUrl);
	const [ currentPage, setCurrentPage ] = useState(page);
	const [ selectedProducts, setSelectedProducts ] = useState();
	const [tabIndex, setTabIndex] = useState(0);
  const [ pageSaved, setPageSaved] = useState(false);
	const [ pageImageUrl, setPageImageUrl] = useState(page.imageUrl);
    
	useEffect(() => {
		setCanvasMode(page.pageType !== 'products');
		setBackground(page.imageUrl);
		setSelectedProducts([]);
		setCurrentPage(page);

    }, [page])


	const backurl = !isEmpty(background) && background != '/' ? background.search("https://") >= 0 ? background : `https://cdn.catalogs.com${background}` : null;

	const updatePageProducts = (products) => {
		savePage({
			variables: {
				id: currentPage.oid,
				payload: {
					pageType: 'products',
					imageUrl: background,
					options: {
						...currentPage.options,
						canvas: null,
						products
					}
				}
			}
		})
			.then(({ data }) => {
				console.log('upage', data.updatePage.page);
				done(data.updatePage);
			})
			.catch((e) => console.log(e));
	};

	const updatePageJson = (width, height, canvas) => {
		console.log('mutation saveCanvas... ', canvas);
		const objects = canvas.objects;
		const products = getCanvasProducts(objects);
		savePage({
			variables: {
				id: currentPage.oid,
				payload: {
					pageType: 'canvas',
					imageUrl: background,
					options: {
						...currentPage.options,
						backgroundColor: !isEmpty(canvas.background) ? canvas.background : '#CCC',
						canvas: {
							width,
							height,
							data: canvas
						},
						products
					}
				}
			}
		})
			.then(({ data }) => {
				done(data.updatePage);
			})
			.catch((e) => console.log(e));
	};

	const getPagesProducts = ()=> {
		let skus = [];
		pages.forEach( (page)=> {
			const products = (!isEmpty(page.options) && !isEmpty(page.options.products))?page.options.products:[]
			products.forEach( (product)=>{
				if(!isEmpty(product.sku))
					skus.push(product.sku)
			})
		})
		return skus;
	}

	useEffect(() => {
	 if (pages)
		 setSelectedProducts(getPagesProducts());
		
	}, [pages])

	const applyPage = (payload) => {
		console.log('applyPage ', payload);
		savePage({
			variables: {
				id: currentPage.oid,
				payload: {
					options: {
						...currentPage.options, ...payload
					}
				}
			}
		})
			.then(({ data }) => {
				console.log('upage', data.updatePage.page);
				done(data.updatePage);
				const updatedPage = {
					...currentPage,
					options: {
						...currentPage.options, ...payload
					}
				};
				setCurrentPage(updatedPage);
			})
			.catch((e) => console.log(e));
	};

	const applyAll = (payload) => {
		pages.forEach((page) => {
			try {
				savePage({
					variables: {
						id: page.oid,
						payload: {
							options: {
								...page.options,
								...payload
							}
						}
					}
				})
					.then(({ data }) => {
					})
					.catch((e) => console.log(e));
			} catch (e) {}
			const updatedPage = {
				...currentPage,
				options: {
					...currentPage.options,
					...payload
				}
			};
			setCurrentPage(updatedPage);
		});
	};

	const getCanvasProducts = (objects) => {
		const uuidv1 = require('uuid/v1');
		return objects.map((object) => {
			const {
				type,
				text,
				left,
				top,
				width,
				height,
				opacity,
				fill,
				stroke,
				linkout,
				linkoutOptions,
				scaleX,
				scaleY,
				visibility,
				src,
				imageUrl,
				showAs
			} = object;

			return {
				sku: linkout,
				type,
				text,
				left,
				top,
				width,
				height,
				opacity,
				fill,
				stroke,
				src,
				imageUrl,
				visibility,
				scaleX,
				scaleY,
				linkout,
				linkoutOptions,
				showAs
			};
		});
	};

	const addSku = (sku)=> {
		setSelectedProducts([...selectedProducts, sku])
	}
	const versionUrl =`${myConfig.CATALOGS_URL}/version/${versionId}/pageonly/${page.oid}`;

	const updatePageImageUrl = (e) =>{
		savePage({
			variables: {
				id: currentPage.oid,
				payload: {
					imageUrl: pageImageUrl,
				}
			}
		})
			.then(({ data }) => {
				console.log('upage', data.updatePage.page);
				done(data.updatePage);
			})
			.catch((e) => console.log(e));
	}

	return (
		<React.Fragment>
			<Segment clearing>
				<Header>
					<div>					
						<Button icon labelPosition='left' onClick={()=>setCanvasMode(!canvasMode)}>
						<Icon name='exchange' />
						{canvasMode?'Page type: Canvas':'Page Type: Product Template'} 
						</Button>
						<Button icon labelPosition='left' onClick={() => goTo(-1)}>
							Previous
						<Icon name='left arrow' />
						</Button>	
						<Button icon labelPosition='left' onClick={() => window.open(versionUrl, '_blank')} >
						<Icon name='external share' />
							Preview
						</Button>
						<Button icon labelPosition='right' onClick={() => goTo(1)}>
							Next
						<Icon name='right arrow' />
						</Button>				
						<CustomizePortal
							page={currentPage}
							applyPage={applyPage}
							applyAll={applyAll}
							setBackground={setBackground}
						/>
						<Button primary type="button" onClick={() => setPageSaved(true)}>
								Save Page
						</Button>                            
						<Confirm
								open={confirmChangesOpen}
								cancelButton="Discard changes"
								confirmButton="Save changes"
								content="You have unsaved changes"
								size="small"
								onCancel={() => {
										setUnsavedChanges(false);
										closePageEditor();
								}}
								onConfirm={() => {
										setPageSaved(true);
								}}
							/>
							<label style={{marginLeft: '5px'}}>{"Page: "+ (page?.position == 0 ? "Cover" : page?.position)}</label>	
            </div>
					</Header>
			</Segment>
			<Segment>
				<label style={{marginLeft: '5px'}}>{"Current Page Url: " + page.imageUrl}</label>					
			</Segment>
			<Segment>
				<Form onSubmit={updatePageImageUrl}>
					<Form.Field>
						<Input
							value={pageImageUrl}
							onChange={(e) => setPageImageUrl(e.target.value)}
							action={
								<Button primary type="submit">Submit</Button>
							}
						/>
					</Form.Field>
				</Form>
			</Segment>

			<Modal.Content>
				<DndProvider backend={Backend}>
					<div className="editor">
					{canvasMode ? (
						<CanvasEditor
							addSku={addSku}
							width={500}
 							height={750}
							page={currentPage}
							background={backurl}
							versionId={versionId}
							savePageJson={(width, height, json) => updatePageJson(width, height, json)}
							pageSaved={pageSaved}
							resetPageSaved={() => setPageSaved(false)}
							closePageEditor={() => closePageEditor()}
							unsavedChanges={unsavedChanges}
							setUnsavedChanges={(bool) => setUnsavedChanges(bool)}
							confirmChangesOpen={confirmChangesOpen}
							resetConfirmChanges={resetConfirmChanges}
						/>
					) : (
						<ProductTemplateEditor
							addSku={addSku}
							page={currentPage}
							background={background}
							fullProducts={catalog.products}
							versionId={versionId}
							onSave={(products) => updatePageProducts(products)}
							pageSaved={pageSaved}
							resetPageSaved={() => setPageSaved(false)}
							closePageEditor={() => closePageEditor()}
							unsavedChanges={unsavedChanges}
							setUnsavedChanges={(bool) => setUnsavedChanges(bool)}
							confirmChangesOpen={confirmChangesOpen}
							resetConfirmChanges={resetConfirmChanges}
						/>
					)}	
						
				<Tabs  selectedIndex={tabIndex} onSelect={(tabIndex) => setTabIndex(tabIndex)}>
				<TabList>
					<Tab>Products</Tab>
					<Tab>Categories</Tab>
				</TabList>
				<TabPanel>
					<DynalogProductsGrid selectedSkus={selectedProducts} isDrag={true} editable={false} />
				</TabPanel>
				<TabPanel>
					<DynalogCategoriesGrid isDrag={true} activeOnly={true} />
				</TabPanel>
				</Tabs>
				</div>
			</DndProvider>
			</Modal.Content>
		</React.Fragment>
	);
};

const CustomizePortal = (props) => {
	const [ open, setOpen ] = useState(false);
	const applyPage = (payload) => {
		props.applyPage(payload);
		props.setBackground(payload.backgroundColor);
		setOpen(false);
	};
	const applyAll = (payload) => {
		props.applyAll(payload);
		setOpen(false);
	};
	var eprops = {
		srcImage: props.background,
		setSrcImage: props.setBackground
	};
	return (
		<React.Fragment>
			<Button content="Customize Page" disabled={open} positive onClick={() => setOpen(true)} />
			<Modal onClose={() => setOpen(false)} open={open}>
				<Header>Customize</Header>
				<Modal.Content>
					<ImageEdit {...eprops} />
					<Button content="Done" negative onClick={() => setOpen(false)} />
					<CustomizePage page={props.page}  onApply={applyPage} applyAll={applyAll} />
				</Modal.Content>
			</Modal>
		</React.Fragment>
	);
};

export default PageEditor;
