import React, { useState, useEffect, useContext } from 'react';
import { withUser } from '../../context/user';
import { Tab } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import PageManager from '../../components/Merchant/pageManager';
import DynalogSettings from '../../components/Merchant/dynalogSettings';
import DynalogProductsGrid from '../../components/Dynalog/productsGrid';
import DynalogCategoriesGrid from '../../components/Dynalog/categoriesGrid';
import PreviewGroup from '../../components/Merchant/previewGroup';
const DynalogEditor = (props) => {
	const [tabIndex, setTabIndex] = useState(0);
	const { id } = useParams();
	console.log('editor', id, props.user);

	if (id) {
		var dyn = props.user.dynalogs.find(xx => xx.ID === id);
		console.log('dyn');
	}
	// props.setUser({...props.user
	// });

	if (!props.user.activeCatalog)
		return '';

	const panes = [
		{
			menuItem: 'Manage',
			render: () =>
				<Tab.Pane attached={false}>
					<PreviewGroup id={props.user.activeCatalog} />
				</Tab.Pane>
		},
		{
			menuItem: 'Products',
			render: () =>
				<Tab.Pane attached={false}>
					{props.user.activeCatalog && <DynalogProductsGrid import={true} editable={true}/>}
				</Tab.Pane>
		},
		{
			menuItem: 'Categories',
			render: () =>
				<Tab.Pane attached={false}>
					{props.user.activeCatalog && <DynalogCategoriesGrid />}
				</Tab.Pane>
		}
	]
	return (
		<div className="content">
			<div className="dynalogWrapper">
				<Tab className="ingestorTab" menu={{ pointing: true }} panes={panes} />
			</div>
		</div>
	);
};

export default withUser(DynalogEditor);
